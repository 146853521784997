<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex align-items-center">
      <div class="row h-100 w-100">
        <div class="col-12 col-md-6 mt-2 my-md-auto">
          <h3 class="page-header-title">Credit Auth</h3>
        </div>
        <div class="col-12 col-md-6 text-end mt-3 mb-3 my-md-auto pe-0">
          <div class="page-header-buttons d-flex align-items-center justify-content-end">
            <router-link :to="{ name: 'new_credit_auth' }" class="text-decoration-none">
              <base-button title="New Request" action="primary" />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!--  Page tabs  -->
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': isActive('pending') }" @click="setActiveTab('pending')">
          Requested <span class="tab-list-item--count">{{ pendingCount }}</span>
        </li>
        <li :class="{ 'active': isActive('signed') }" @click="setActiveTab('signed')">
          Signed <span class="tab-list-item--count">{{ signedCount }}</span>
        </li>
      </ul>
    </div>

    <div class="page-content p-normal">
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField" aria-label="search" @keyup="search">
          </div>
        </div>
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table" v-else>
          <table class="table">
            <thead>
            <tr>
              <th>Borrower</th>
              <th>Doc Status</th>
              <th>Request Sent on</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="doc in docs" :key="doc.id">
              <td>
                <div class="d-flex align-items-center">
                  <div class="user-avatar-block">
                    <v-avatar :size="53" rounded :username="doc.fullName" />
                  </div>
                  <div class="user-info">
                    <div class="user-name">{{ doc.fullName }}</div>
                    <div class="user-email">{{ doc.email }}</div>
                  </div>
                </div>
              </td>
              <td>
                <div class="doc-status" :class="{pending: doc.status === 'pending', signed:  doc.status === 'signed'}">
                  {{ doc.status }}
                </div>
              </td>
              <td>
                {{ formatDate(doc.requestSentOn, 'llll') }}
              </td>
              <td v-if="doc.status === 'pending'">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-grey" @click="remind(doc.uuid)">
                    Remind
                  </button>
                  <router-link :to="{ name: 'credit_auth_details', params: {uuid: doc.uuid} }">
                    <button class="btn btn-outline-grey ms-2">
                      Details
                    </button>
                  </router-link>
                </div>
              </td>
              <td v-else>
                <div class="d-flex justify-content-end">
                  <router-link :to="{ name: 'signed_credit_auth', params: {uuid: doc.uuid} }">
                    <button class="btn btn-outline-grey">
                      View Documents
                    </button>
                  </router-link>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue'
export default {
  components: { BaseButton },
  name: 'CreditAuth',
  data() {
    return {
      activeTab: 'pending',
      searchField: '',
      ui: {
        loading: false,
        searchTimer: null
      },
      docs: [],
      pendingCount: 0,
      signedCount: 0
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName
      this.getData()
    },
    isActive(tabName) {
      return this.activeTab === tabName
    },
    getData() {
      let params = {
        status: this.activeTab,
        query: this.searchField
      }
      this.ui.loading = true

      this.$http.get('/api/v1/credit-auth', { params: params })
        .then(response => {
          this.docs = response.data.docs
          this.pendingCount = response.data.pendingCount
          this.signedCount = response.data.signedCount
          this.ui.loading = false
        })
        .catch()
    },
    remind(uuid) {
      if(confirm('Send remind email?')) {
        this.$http.get(`/api/v1/credit-auth/remind/${uuid}`)
          .then(() => {
            this.getData()
            this.pushAlert('success', 'Email reminder sent successfully')
          })
      }
    },
    search() {
      this.ui.loading = true;
      if (this.ui.searchTimer) {
        clearTimeout(this.ui.searchTimer);
      }

      this.ui.searchTimer = setTimeout(() => { this.getData() }, 800);
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-new-request {
  min-width: 170px;
}

.table {
  tbody {
    tr {
      &:nth-child(even) {
        background: none!important;
        td {
          background: none;
        }
      }
      td:not(:first-child) {
        padding-left: 0;
      }
      td {
        height: 85px;
      }
    }
  }
  .user-name {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;
    margin-bottom: 3px;
  }
  .user-email {
    color: rgba(0,0,0,.7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: normal;
  }

  .doc-status  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    border-radius: 14px;
    width: fit-content;
    padding: 4.5px 12.5px;
    text-transform: uppercase;

    &.pending {
      color: rgba(0,0,0,.7);
      border: 1px solid rgba(0,0,0,0.7);
      border-radius: 12px;
    }

    &.signed {
      color: #FFFFFF!important;
      border: 1px solid #00AB34!important;
      background-color: #00AB34!important;
    }
  }

  button {
    &.btn-outline-grey {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      max-height: 32px;
      height: 32px;
      width: fit-content;
      padding: 0 21.5px;
      color: rgba(0,0,0,.7);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      border-radius: 8px;
      border: 1px solid #8A8A8A;
      transition: all .3s;
      &:hover {
        background: #eeeeee;
      }
    }
  }
  a {
    text-decoration: none;
  }
}
</style>
